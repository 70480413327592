var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

(function ($, generic) {
  /**
   * Event listener to initalize the waitlist button when a product is sold out or coming soon.
   * Event call is coming from js/site/product_ui.js when a product is initalized or a sku is selected.
   * Passes product data to the init method of productWaitlistV1.
   */
  $(document).on('product.waitlist.init', function (event, data) {
    Drupal.behaviors.productWaitlistV1.handleVisibilityState(data);
  });

  /**
   * Event listener to reset or hide the waitlist button when a product is available.
   * Passes product data to the reset method of productWaitlistV1.
   */
  $(document).on('product.waitlist.reset', function (event, data) {
    Drupal.behaviors.productWaitlistV1.handleVisibilityState(data);
  });

  Drupal.behaviors.productWaitlistV1 = {

    /**
     * Method to declare DOM classes used by waitlist.
    */
    selectors: {
      waitlistBtn: '.js-add-to-waitlist',
      cartBtn: '.js-add-to-cart, .js-add-to-bag',
      qtyInput: '.js-quantity--menu__container',
      container: '.js-add-to-cart',
      enabled: '.js-waitlist-enabled',
      hidden: '.hidden',
      active: '.active'
    },
    classes: {
      waitlistEnabled: 'js-waitlist-enabled',
      hidden: 'hidden',
      active: 'active',
      disabled: 'button--disabled'
    },

    /**
     * Status ids used by the waitlist; adjust accordingly.
     */
    statusIds: {
      inventory: {
        tempOOS: 2,
        comingSoon: 3,
        soldOut: 7,
        inStock: 1
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    },

    attach: function (context, settings) {
      const that = this;

      $(this.selectors.waitlistBtn, context).once('waitlist-init').each(function () {
        const $waitlistBtn = $(this);
        const productId = $waitlistBtn.data('product-id');
        const skuBaseId = $waitlistBtn.data('sku-base-id');

        if (productId && skuBaseId && typeof prodcat !== 'undefined' && prodcat.data) {
          const skuData = prodcat.data.getSku(skuBaseId);

          if (skuData) {
            that.handleVisibilityState(skuData);
          }
        }
      });
    },

    handleVisibilityState: function (data) {
      if (!data || !data.PRODUCT_ID) {
        return null;
      }

      const isSoldOut = data.INVENTORY_STATUS === this.statusIds.inventory.soldOut;
      const enableCs = Drupal.settings.common?.enable_cs_notify || false;
      const visibilityState = this.determineVisibilityState(data);
      const $waitlistBtn = $(this.selectors.waitlistBtn).filter(`[data-product-id="${data.PRODUCT_ID}"]`);
      const $productContainer = $waitlistBtn.closest('.js-atb-container, .js-product');
      const $cartBtns = $(this.selectors.cartBtn).filter(`[data-product-id="${data.PRODUCT_ID}"]`);
      const $qtyInput = $productContainer.find(this.selectors.qtyInput);

      if (!$waitlistBtn.length) {
        return null;
      }

      this.toggleElementVisibility($waitlistBtn, $cartBtns, $qtyInput, visibilityState, enableCs, data, isSoldOut);
    },
    determineVisibilityState: function (data) {
      const isSoldOut = data.INVENTORY_STATUS === this.statusIds.inventory.soldOut;
      const isTempOOS = data.INVENTORY_STATUS === this.statusIds.inventory.tempOOS;
      const isComingSoon = data.INVENTORY_STATUS === this.statusIds.inventory.comingSoon;
      const isBasicReorder = data.LIFE_OF_PRODUCT === this.statusIds.lifeOfProduct.basicReorder;

      if (isComingSoon || isTempOOS || (isSoldOut && isBasicReorder)) {
        return 'show';
      }

      return 'hide';
    },

    toggleElementVisibility: function ($waitlistBtn, $cartBtns, $qtyInput, state, enableCs, data, isSoldOut) {
      if (state === 'show') {
        $waitlistBtn
          .removeClass(this.classes.hidden)
          .addClass(this.classes.active)
          .addClass(this.classes.waitlistEnabled)
          .attr('data-sku-base-id', data.SKU_BASE_ID);

        if (!enableCs) {
          if (isSoldOut) {
            if ($qtyInput.length) {
              $qtyInput.addClass(this.classes.hidden);
            }
            $cartBtns.each((index, btn) => {
              const $btn = $(btn);

              $btn
                .addClass(this.classes.disabled)
                .attr('data-disabled', 'true')
                .prop('disabled', true)
                .off('click.soldout')
                .on('click.soldout', function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                });
            });
          } else {
            $cartBtns.addClass(this.classes.hidden);
            if ($qtyInput.length) {
              $qtyInput.addClass(this.classes.hidden);
            }
          }
        }

        $waitlistBtn.off('click').on('click', function (e) {
          e.preventDefault();
          $(document).trigger('product.waitlist.overlay', data);
        });
      } else {
        $waitlistBtn
          .removeClass(this.classes.active)
          .removeClass(this.classes.waitlistEnabled)
          .addClass(this.classes.hidden);

        $cartBtns.each((index, btn) => {
          const $btn = $(btn);

          $btn
            .removeClass(this.classes.hidden)
            .removeClass(this.classes.disabled)
            .attr('data-disabled', 'false')
            .prop('disabled', false)
            .off('click.soldout');
        });

        if ($qtyInput.length) {
          $qtyInput.removeClass(this.classes.hidden);
        }
      }
      $cartBtns.each(function () {
        const $btn = $(this);
        const $btnCopy = $btn.find('.js-add-to-cart__copy');
        const $btnLabels = $btn.data('inv-status-labels');

        if ($btnLabels && $btnLabels[data.INVENTORY_STATUS]) {
          $btnCopy.text($btnLabels[data.INVENTORY_STATUS]);
        }
      });

      if (isSoldOut) {
        if ($qtyInput.length) {
          $qtyInput.addClass(this.classes.hidden);
        }
        $cartBtns.each((index, btn) => {
          const $btn = $(btn);
          if (!$btn.hasClass(this.classes.disabled)) {

            $btn
              .addClass(this.classes.disabled)
              .attr('data-disabled', 'true')
              .prop('disabled', true);
          }
        });
      }
    }
  };
})(jQuery);
